export default {
  selectTecnicosAsignados (Vue, idparteTrabajo) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.parte_trabajo_tecnico)
      .innerJoin(
        tables.tecnico,
        tables.parte_trabajo_tecnico.idtecnico.eq(tables.tecnico.idtecnico)
      )
      .innerJoin(
        tables.empleado,
        tables.tecnico.idempleado.eq(tables.empleado.idempleado)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.parte_trabajo_tecnico.idparte_trabajo.eq(idparteTrabajo)
        )
      )
      .exec()
  },
}
