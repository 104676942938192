<template>
  <b10-base>
    <v-radio-group
      v-if="tecnicosAsignados.length > 1 && permiteIniciarCronometroTodos && hasPermIntroducirOtrosTecnicos"
      v-model="form.para"
    >
      <template
        v-for="para in paraOptions"
      >
        <v-radio
          :key="para.id"
          :label="para.descripcion"
          :value="para.id"
        />
      </template>
    </v-radio-group>
    <v-checkbox
      v-model="form.es_desplazamiento"
      label="Es desplazamiento"
    />
    <v-text-field
      v-model.number="form.latitud"
      label="Latitud"
      disabled
    />
    <v-text-field
      v-model.number="form.longitud"
      label="Longitud"
      disabled
    />
    <v-btn
      v-show="form.latitud && form.longitud"
      block
      color="secondary"
      dark
      @click.stop="clickIrMapa(form.latitud, form.longitud)"
    >
      <v-icon
        left
      >
        {{ $vuetify.icons.values.mapLocation }}
      </v-icon> Localización
    </v-btn>
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './CronometroIniciarFormData'
import _ from '@/utils/lodash'
import { CRONOMETRO, PARAMETRO } from '@/utils/consts'
import { getLatLongURL } from '@/utils/maps'

export default {
  mixins: [formMixin],
  props: {
    geolocalizacion: {
      type: Object,
      default: () => {},
    },
    idparteTrabajo: {
      type: [Number, String],
      required: true,
    },
    hasPermIntroducirOtrosTecnicos: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      form: {
        para: CRONOMETRO.para.soloParaMi,
        es_desplazamiento: false,
        latitud: null,
        longitud: null,
      },
      tecnicosAsignados: [],
      paraOptions: {
        soloParaMi: {
          descripcion: 'Solo para mí',
          id: CRONOMETRO.para.soloParaMi
        },
        paraTodos: {
          descripcion: 'Para todos los técnicos',
          id: CRONOMETRO.para.paraTodos
        },
      },
      permiteIniciarCronometroTodos: false,
    }
  },
  watch: {
    geolocalizacion: {
      handler: function (newValue) {
        if (newValue) {
          this.$set(this.form, 'latitud', newValue.lat)
          this.$set(this.form, 'longitud', newValue.lng)
        }
      },
      deep: true,
    },
  },
  async created () {
    this.tecnicosAsignados = await Data.selectTecnicosAsignados(this, this.idparteTrabajo)
    const listaTecnicos = _.map(
      this.tecnicosAsignados, 'empleado.nombre_y_apellido'
    ).join(', ')
    if (this.tecnicosAsignados.length > 1) {
      this.paraOptions.paraTodos.descripcion = `Para todos los técnicos (${listaTecnicos})`
    }
    this.permiteIniciarCronometroTodos = (
      await this.$offline.parametro.valorParametro(PARAMETRO.parametros.INICIAR_DETENER_CRONOMETRO_TODOS)
    ) === '1'
    await this.loadForm()
  },
  methods: {
    async loadFormData () {
      // no tiene posibilidad de edicion
    },
    clickIrMapa (lat, lng) {
      window.open(getLatLongURL(lat, lng), '_blank')
    },
  }
}
</script>
